const colors = [
  {
    left: "#4F4B6B",
    right: "#9A84BC",
    core: "#7C679C"
  },
  {
    left: "#EDB240",
    right: "#38ADBD",
    core: "#CBA162"
  },
  {
    left: "#FAEA5C",
    right: "#7FC5E3",
    core: "#ACD38D"
  },
  {
    left: "#E3CBAB",
    right: "#8E9593",
    core: "#B4AC94"
  },
  {
    left: "#282828",
    right: "#F3F3F3",
    core: "#808080"
  },
  {
    left: "#FFE381",
    right: "#BBA6F4",
    core: "#90CCF8"
  },
  {
    left: "#7EFFFC",
    right: "#13587A",
    core: "#7AD4FF"
  },
  {
    left: "#B5B8BA",
    right: "#F1F2A1",
    core: "#D4D4AC"
  }
]

export default colors;